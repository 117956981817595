import React, { useState, useEffect } from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout/Layout"
import "typeface-lato"
import "isomorphic-fetch"

const NumbersPage = () => {
  const [dataJson, setDataJson] = useState({})

  useEffect(() => {
    fetch("/php/counter.php", { method: "GET" })
      .then(result => {
        return result.json()
      })
      .then(response => {
        setDataJson(response)
      })
      .catch(error => {
        console.error("counter", error)
      })
  }, [])

  return (
    <>
      <SEO title="Informationen" />

      <Layout>
        <div className="numbers-wrapper">
          <h1>Übersicht der Aufrufe</h1>
          <div className="numbers-overview">
            {Object.keys(dataJson).map((element, index) => {
              return (
                <p key={index}>
                  <b>{element}:</b> {dataJson[element]}
                </p>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default NumbersPage
